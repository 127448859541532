.blogNavBar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.blogNavBar button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.blogNavBar button:disabled {
  background-color: #d6d6d6;
  cursor: not-allowed;
}

.blogNavBar button:not(:disabled):hover {
  background-color: #0056b3;
}

.blogNavBar a {
  margin: 0 5px;
  padding: 10px 15px;
  text-decoration: none;
  color: #007bff;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.blogNavBar a.active {
  background-color: #007bff;
  color: white;
}

.blogNavBar a:hover {
  background-color: #0056b3;
  color: white;
}

.slider-container {
  position: relative;
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.slider {
  width: 100%;
  appearance: none;
  height: 10px;
  background: linear-gradient(90deg, #007bff 0%, #00c6ff 100%);
  outline: none;
  opacity: 0.8;
  transition: opacity 0.2s, background 0.3s;
  cursor: pointer;
  border-radius: 5px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  background: #ff6b6b;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;
}

.slider::-webkit-slider-thumb:hover {
  background: #ff4b4b;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #ff6b6b;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;
}

.slider::-moz-range-thumb:hover {
  background: #ff4b4b;
}
