/* Enhanced Typewriter Animation */
@keyframes typewriter {
    0% {
      width: 0;
    }
    60% {
      width: 100%; /* Text fully typed */
    }
    80% {
      width: 95%; /* Small backspace effect */
    }
    100% {
      width: 100%; /* Fully typed again */
    }
  }
  
  /* Enhanced Cursor Blink */
  @keyframes blinkCursor {
    0% {
      border-right-color: #ffffff;
    }
    50% {
      border-right-color: transparent;
    }
    100% {
      border-right-color: #ffffff;
    }
  }
  
  /* Multi-Layered Glitch Effect */
  @keyframes glitch {
    0% {
      text-shadow: -2px 2px #ff3e3e, 2px -2px #30ffff, -3px -3px 5px #ffd700;
      transform: translate(0, 0);
    }
    20% {
      text-shadow: 2px -2px #ff3e3e, -2px 2px #30ffff, 3px 3px 5px #ffd700;
      transform: translate(-3px, 3px);
    }
    40% {
      text-shadow: -2px -2px #ff3e3e, 2px 2px #30ffff, -3px 3px 5px #ffd700;
      transform: translate(3px, -3px);
    }
    60% {
      text-shadow: 2px 2px #ff3e3e, -2px -2px #30ffff, 3px -3px 5px #ffd700;
      transform: translate(-3px, 3px);
    }
    80% {
      text-shadow: -2px 2px #ff3e3e, 2px -2px #30ffff, -3px -3px 5px #ffd700;
      transform: translate(3px, -3px);
    }
    100% {
      text-shadow: 2px -2px #ff3e3e, -2px 2px #30ffff, 3px 3px 5px #ffd700;
      transform: translate(0, 0);
    }
  }
  
  /* Color Pulsation */
  @keyframes colorPulse {
    0% {
      color: #ffffff;
    }
    25% {
      color: #42a5f5; /* Light blue */
    }
    50% {
      color: #000000; /* Yellow */
    }
    75% {
      color: #ebebeb; /* Red */
    }
    100% {
      color: #ffffff;
    }
  }
  
  /* Final Typewriter with Enhanced Effects */
  .typewriter-glitch {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid #ffffff;
    width: 16ch; /* Adjust length based on text */
    animation: typewriter 6s steps(30, end) infinite, blinkCursor 0.7s step-end infinite, colorPulse 6s infinite;
    
    position: relative;
  }
  
  /* Adding Glitch Effect on Hover */
  .typewriter-glitch:hover {
    animation: glitch 0.2s infinite, colorPulse 2s infinite;
  }
  
  /* Additional Spark Effect */
  .typewriter-glitch:before,
  .typewriter-glitch:after {
    content: "Neal Frazier Tech";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #ffffff;
    background: transparent;
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
    text-shadow: -2px 0 #e9e9e9, 2px 2px #000000;
    opacity: 0.6;
    transform: translate(-2px, -2px);
    z-index: -1;
    animation: glitch 0.5s infinite;
  }
  
  .typewriter-glitch:after {
    clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
    transform: translate(2px, 2px);
    opacity: 0.4;
  }
  