@font-face {
  font-family: 'Katakana-Font'; /* You can name this anything you like */
  src: url('/public/fonts/KatakanaFont.ttf') format('truetype'); /* Adjust the path */
  font-weight: normal; /* Adjust if needed */
  font-style: normal; /* Adjust if needed */
}

@font-face {
  font-family: 'AntiMatrix-Font';
  src: url(/public/fonts/AntiMatrixFont.ttf);
}

@font-face {
  font-family: 'Matrix-Font';
  src: url(/public/fonts/MatrixFont.ttf);
}
@font-face {
  font-family: 'LCD-Font';
  src: url(/public/fonts/LcdFont.ttf);
}

@font-face {
  font-family: 'Future-Tech-Font';
  src: url(/public/fonts/FutureTech.ttf);
}

@font-face {
  font-family: 'Crypto-Crash-Font';
  src: url(/public/fonts/CryptoCrashFont.ttf);
}

@font-face {
  font-family: 'Orbitron';
  src: url(/public/fonts/OrbitronFont.ttf);
}


body, html {
  cursor: url(/public/assets/cursorlogo.png) 30 30, auto;
}

@keyframes glitchText {
  0% {
    text-shadow: 2px 2px 0px #ff00ff, -2px -2px 0px #00ffff;
  }
  25% {
    text-shadow: -2px -2px 0px #ff00ff, 2px 2px 0px #00ffff;
  }
  50% {
    text-shadow: 4px 4px 0px #ff00ff, -4px -4px 0px #00ffff;
  }
  75% {
    text-shadow: -2px -2px 0px #ff00ff, 2px 2px 0px #00ffff;
  }
  100% {
    text-shadow: 2px 2px 0px #ff00ff, -2px -2px 0px #00ffff;
  }
}

.glitch-container {
  animation: glitchImage 1.5s infinite;
}

@keyframes glitchImage {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(2px, -2px);
  }
  50% {
    transform: translate(-2px, 2px);
  }
  75% {
    transform: translate(2px, 2px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

