/* styles.css */
.box {
    width: auto;
    height: 50vmin;
    display: grid;
    place-content: center;
    color: white;
    text-shadow: 0 1px 0 #000;
    
    --border-angle: 0turn;
    --main-bg: conic-gradient(
      from var(--border-angle),
      rgb(0, 0, 0),
      rgb(0, 0, 0) 5%,
      rgb(0, 0, 0) 60%,
      rgb(0, 0, 0) 95%
    );
    
    border: solid 5px transparent;
    border-radius: 2em;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #000000, #ffffff, #5a5a5a 99%, transparent);
    
    background: 
      var(--main-bg) padding-box,
      var(--gradient-border) border-box, 
      var(--main-bg) border-box;
  
    background-position: center center;
    animation: bg-spin 3s linear infinite;
  }
  
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
  
  .box:hover {
    animation-play-state: paused;
  }
  
  @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }
  