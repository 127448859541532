@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,700;1,400&family=Roboto+Slab:wght@400;700&display=swap');

/* BlogPost.module.css */
:root {
    --transition-speed: 0.3s;
    --color-primary: #007bff;
    --color-secondary: #3d5a80;
    --color-text: #343a40;
    --color-accent: #293241;
    --font-primary: 'LCD-Font', cursive; /* For headers */
    --font-secondary: 'Orbitron', serif; /* For body text */
  }
 
  .blogPost {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #00000054;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: var(--font-secondary);
    overflow: hidden;
    transition: box-shadow var(--transition-speed) ease;
    padding-top: 100px;
  }
  
  .blogPost:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  }
  
  .blogHeader h1 {
    color: var(--color-accent);
    text-align: center;
    font-size: 2.5rem !important;
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: -0.5px;
    line-height: 1.2;
    transition: color var(--transition-speed) ease, text-shadow var(--transition-speed) ease;
    text-shadow: 1px 1px 0 rgba(0,0,0,0.1);
    font-family: 'LCD-Font' !important;
  }
  
  .blogHeader h1:hover {
    color: var(--color-secondary);
    text-shadow: 2px 2px 15px rgba(0,0,0,0.2); /* More pronounced shadow for depth */
  }
  
  .blogContent p, .blogContent blockquote, .blogContent ul, .blogContent li {
    transition: transform var(--transition-speed) ease;
  }
  
  .blogContent p:hover, .blogContent blockquote:hover, .blogContent ul:hover, .blogContent li:hover {
    transform: translateX(10px); /* Subtle shift to indicate interactivity */
  }
  
  .blogContent h2 {
    color: var(--color-secondary);
    font-size: 2rem !important;
    margin-top: 40px;
    margin-bottom: 15px;
    font-weight: 600;
    position: relative;
    padding-bottom: 5px;
    text-align: center;
    transition: all var(--transition-speed) ease;
    font-family: 'AntiMatrix-Font' !important;
  }
  
  .blogContent h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(--color-secondary);
    width: 50%;
    margin: auto;
    transition: width var(--transition-speed) ease;
  }
  
  .blogContent h2:hover::after {
    width: 100%; /* Expand underline on hover */
  }
  
  .blogPost a {
    color: var(--color-primary);
    text-decoration: none;
    position: relative;
  }
  
  .blogPost a::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -3px;
    left: 0;
    background-color: var(--color-primary);
    transform-origin: bottom right;
    transition: transform var(--transition-speed) ease-out;
  }
  
  .blogPost a:hover::after, .blogPost a:focus::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
  li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 8px;
    height: 8px;
    background-color: var(--color-primary);
    border-radius: 50%;
    transition: background-color var(--transition-speed) ease;
  }
  
  li:hover::before {
    background-color: darken(var(--color-primary), 10%);
  }
  
  .blogPost img {
    max-width: 100%;
    height: 250px;
    display: block;
    margin: 20px auto;
    transition: transform var(--transition-speed) ease-out;
  }
  
  .blogPost img:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }
  
  @media (max-width: 600px) {
    .blogHeader h1 {
      font-size: 2rem;
    }
  
    .blogContent h2 {
      font-size: 1.75rem;
    }
  }
  